import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useAuth, useEditableContent } from '../../../../context/AuthContext'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import LoginDetailsModal from './LoginDetailsModal'
import CopyToClipboard from '../../../../components/CopyToClipboard/CopyToClipboard'

const LoginDetails = ({ advertiserId, manager, showSystemMessage }) => {
    const auth = useAuth()
    const showLoginSettings = useEditableContent(auth.permissions, 'advertiser_login_settings')

    const { data, dataLoaded, setData, refetch, setDataLoaded } = useCallApi(`advertisers/${advertiserId}/logindetails`, 'GET', {}, [advertiserId])
    const [id, setId] = useState(0)
    const [isVisible, setIsVisible] = useState(false)

    const getUrl = (url) => {
        let formattedURL = url.substring(0, 4) === 'http' ? url : url.substring(0, 5) === 'https' ? url : `http://${url}`

        return formattedURL
    }

    return (
        <Card className="mb-3">
            {showLoginSettings && <LoginDetailsModal setDataLoaded={setDataLoaded} showSystemMessage={showSystemMessage} modalData={data} setModalData={setData} id={id} dataLoaded={dataLoaded} manager={manager} refetch={refetch} advertiserId={advertiserId} />}
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Login Details</span>
                </span>
                {showLoginSettings && (
                    <Button variant="warning" size="sm" className="mr-2" onClick={() => setData({ ...data, isOpen: true, isCreate: true })}>
                        Add Login
                    </Button>
                )}
            </Card.Header>
            <Card.Body className="overflow-auto p-2">
                {dataLoaded ? (
                    <>
                        {data.loginDetails.length === 0 ? (
                            <div>No Login Details Available</div>
                        ) : (
                            data.loginDetails.map((item, index) => {
                                return (
                                    <Card className="bg-light d-flex p-3 mb-3" key={index}>
                                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                            <div className="d-flex flex-column">
                                                <div>
                                                    Site: <a target="_blank" href={getUrl(item.url)}>{item.url}</a>
                                                </div>
                                                <div>Login: {item.login}</div>
                                                <div className='align-items-center'>
                                                    <span>Password: {isVisible ? item.password : '*'.repeat(item.password.length)}</span><span className='ml-4'>{isVisible ? <button className="btn btn-outline-primary btn-sm" onClick={() => setIsVisible(!isVisible)}><FontAwesomeIcon icon={faEye} size={"1x"} /></button> : <FontAwesomeIcon icon={faEyeSlash} size={"1x"} color="#007bff" onClick={() => setIsVisible(!isVisible)} cursor="pointer" />} <CopyToClipboard content={item.password} /> </span>                                                </div>
                                            </div>
                                            {showLoginSettings && (
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    onClick={() => {
                                                        setData({ ...data, isOpen: true, isCreate: false })
                                                        setId(index)
                                                    }}
                                                    style={{ height: '2rem' }}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                        <hr />
                                        <div>Notes: {item.notes}</div>
                                    </Card>
                                )
                            })
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Card.Body>
        </Card>
    )
}

export default LoginDetails
