import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css'

export default function DateRangeFilter({ selectedStartDate, selectedEndDate, handleChangeStart, handleChangeEnd, quickDate, handleQuickDate, singleDate }) {
    return (
        <React.Fragment>
            <div>
                {!singleDate && (
                    <div className="mr-2">
                        <select className="custom-select custom-select-sm" onChange={handleQuickDate} value={quickDate}>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="month">This Month</option>
                            <option value="lastMonth">Last Month</option>
                            <option value="thisYear">This Year</option>
                            <option value="thisPayPeriod">This Pay Period</option>
                            <option value="lastPayPeriod">Last Pay Period</option>
                            <option value="custom">Custom Date</option>
                        </select>
                    </div>
                )}
            </div>
            {!singleDate && quickDate === 'custom' && (
                <div className="d-flex mr-2">
                    <DatePicker
                        selectsStart /* To signify it's the "start" picker */
                        selected={selectedStartDate ? moment(selectedStartDate).toDate() : null}
                        startDate={selectedStartDate ? moment(selectedStartDate).toDate() : null}
                        endDate={selectedEndDate ? moment(selectedEndDate).toDate() : null}
                        onChange={(date) => {
                            handleChangeStart(date)
                        }}
                        className="form-control form-control-sm display-inline-block mr-2"
                        todayButton={'Today'}
                    />
                    <div className="d-flex mx-2 align-items-center">to</div>
                    <DatePicker
                        selectsEnd /* To signify it's the "end" picker */
                        selected={selectedEndDate ? moment(selectedEndDate).toDate() : null}
                        startDate={selectedStartDate ? moment(selectedStartDate).toDate() : null}
                        endDate={selectedEndDate ? moment(selectedEndDate).toDate() : null}
                        onChange={(date) => {
                            handleChangeEnd(date)
                        }}
                        className="form-control form-control-sm display-inline-block mr-2"
                        todayButton={'Today'}
                    />
                </div>
            )}

            {singleDate && (
                <div className="d-flex mr-2">
                    <DatePicker
                        selectsStart /* To signify it's the "start" picker */
                        selected={selectedStartDate ? moment(selectedStartDate).toDate() : null}
                        startDate={selectedStartDate ? moment(selectedStartDate).toDate() : null}
                        endDate={selectedEndDate ? moment(selectedEndDate).toDate() : null}
                        onChange={(date) => {
                            handleChangeStart(date)
                        }}
                        className="form-control form-control-sm display-inline-block mr-2"
                        todayButton={'Today'}
                    />
                </div>
            )}
        </React.Fragment>
    )
}
